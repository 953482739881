<template>
    <AppIcon class="loading-icon" icon="refresh-line"/>
</template>
<script>
import AppIcon from "./AppIcon";
export default {
    name: 'LoadingIcon',
    components: {AppIcon}
}
</script>
<style lang="scss">
.loading-icon {
    animation: rotate 1s infinite linear;
}
@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
