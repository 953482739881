function bind(el, value, e) {
    let target = e.target;
    while(target) {
        if(target === el) {
            return;
        } else {
            target = target.parentElement;
        }
    }
    value()
}
const clickaway = {
    inserted: function(el, {value}) {
        el.$handler = bind.bind(null, el, value);
        window.addEventListener('mousedown', el.$handler);
    },
    unbind(el) {
        window.removeEventListener('mousedown', el.$handler)
    }
}

export default clickaway;
